import React from 'react';
import dynamic from 'next/dynamic';
import styled from '@emotion/styled';
import { COLORS3, SPACING } from '@zola/zola-ui/src/styles/emotion';

import type { WCmsPoiView, WCmsThingsToDoPageView } from '@zola/svc-web-api-ts-client';

import TitleImgDesc from 'components/publicWebsiteV2/common/TitleImgDesc';

import getNonHomeCmsHeroImg from 'components/publicWebsiteV2/util/getNonHomeCmsHeroImg';

import { useAppSelector } from 'reducers/useAppSelector';
import { getPoiMapEnabled } from 'selectors/public/publicWebsiteSelectors';
import { isDarkColor } from 'pages/publicStyleUtils/utils.styles';

import { useWebsiteThemeContext } from 'components/publicWebsiteV2/context';

import { hasRenderableAddress } from 'components/publicWebsiteV2/common/Address/Address';
import {
  Container,
  Poi,
  Title,
  AddressAndPhone,
  StyledAddress,
  Image,
  MapContainer,
  buttonStyles,
} from './ThingsToDo.styles';

const StaticMap = dynamic(() => import('components/common/ui/StaticMap/StaticMap'), { ssr: false });

export type ThingsToDoProps = {
  pageData?: WCmsThingsToDoPageView;
};

const ThingsToDo = ({ pageData }: ThingsToDoProps): JSX.Element => {
  const { title, description, pois, header_image_url, images } = pageData || {};
  const mapEnabled = useAppSelector(getPoiMapEnabled);
  const {
    state: {
      components: {
        styleCmsEntityHeaderFont,
        styleCmsEntityBodyFont,
        globalAccentColor,
        ThemedButton,
      },
      inPreview,
    },
  } = useWebsiteThemeContext();

  const markerColor = isDarkColor(globalAccentColor)
    ? globalAccentColor
    : COLORS3.BLACK_100.substring(1);

  const Para = styled.p`
    margin-bottom: ${SPACING.SM};
    white-space: pre-wrap;
  `;

  const renderImg = (poi: WCmsPoiView) => {
    const renderImgTag = () => <Image src={`${poi.image_url}?h=150`} alt={poi.title} />;
    if (poi.url) {
      return (
        <a href={poi.url} target="_blank" rel="noopener noreferrer ugc">
          {renderImgTag()}
        </a>
      );
    }
    return renderImgTag();
  };

  const markers = (pois || [])
    .filter(poi => poi.latitude)
    .map(poi => ({
      position: {
        lat: parseFloat(poi.latitude as string),
        lng: parseFloat(poi.longitude as string),
      },
      title: poi.title as string,
    }));

  const showEmptyStateMessage = pois?.length === 0 && !description;

  const cmsHeaderImgUrl = getNonHomeCmsHeroImg(images);

  const StyledTitle = styleCmsEntityHeaderFont(Title);
  const StyledP = styleCmsEntityBodyFont(Para);
  const StyledAddressAndPhone = styleCmsEntityBodyFont(AddressAndPhone);

  return (
    <>
      <TitleImgDesc
        title={title}
        url={cmsHeaderImgUrl || header_image_url}
        description={description}
      />
      {mapEnabled && markers.length > 0 && (
        <MapContainer>
          <StaticMap
            markers={markers}
            theme={{
              defaultMarkerColor: markerColor,
            }}
            showDefaultMapStyling
            forPreview={false}
          />
        </MapContainer>
      )}
      {pois && pois.length > 0 && (
        <Container>
          {pois?.map(poi => {
            const handleClickBtn = () => {
              if (inPreview) return;
              if (typeof window !== 'undefined') {
                // DO NOT REMOVE - Required for affiliate tracking
                try {
                  window.vglnk.open(poi.url, '_blank');
                } catch (err) {
                  window.open(poi.url, '_blank', 'noopener, noreferrer')?.focus();
                }
              }
            };
            return (
              <Poi key={poi.id}>
                {poi.image_id && poi.image_url && renderImg(poi)}
                <StyledTitle>{poi.title}</StyledTitle>
                <StyledAddressAndPhone>
                  <StyledAddress
                    singleLine
                    addressData={{
                      address1: poi.address_1,
                      address2: poi.address_2,
                      city: poi.city,
                      state: poi.state_province,
                      zip: poi.postal_code,
                      countryCode: poi.country_code,
                    }}
                  />
                  {poi.contact_phone && hasRenderableAddress(poi) && ', '}
                  {poi.contact_phone ? poi.contact_phone : null}
                </StyledAddressAndPhone>
                {poi.description && <StyledP>{poi.description}</StyledP>}
                {poi.url && (
                  <ThemedButton css={buttonStyles} onClick={handleClickBtn}>
                    View
                  </ThemedButton>
                )}
              </Poi>
            );
          })}
        </Container>
      )}
      {showEmptyStateMessage && (
        <StyledP style={{ textAlign: 'center' }}>
          We&apos;re making a list of our favorite activities and sights in the area. Check back
          soon.
        </StyledP>
      )}
    </>
  );
};

export default ThingsToDo;
